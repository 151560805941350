/**
* Generated automatically at built-time (2024-11-04T09:47:54.963Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "cuisine-d-exterieur-housses-de-protection-sur-mesure",templateKey: "sites/104-9b9aa47c-9ef8-4c5f-a5a5-daafa91c7188"};