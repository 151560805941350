/**
* Generated automatically at built-time (2024-11-04T09:47:55.202Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "idee-cuisine-exterieur",templateKey: "sites/104-91fa3bfd-e6de-4099-be17-c24439541d5e"};